<script setup lang="ts">
import type { Notification } from '~/types/Notification';

// PROPS
interface Props {
    isSeen: boolean;
    notification: Notification;
}
const props = withDefaults(defineProps<Props>(), { isSeen: true });
const { notification } = toRefs(props);

// VARIABLE
const timeAgo = useTimeAgo(new Date(notification.value.createdAt));
const iconStyle: Record<number, { class: string; icon: string }> = {
    0: { class: 'bg-sky-500 dark:bg-sky-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:info-outline-rounded' },
    1: { class: 'bg-yellow-500 dark:bg-yellow-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:quick-reference-all-outline-rounded' },
    2: { class: 'bg-green-500 dark:bg-green-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:verified-outline-rounded' },
    3: { class: 'bg-green-500 dark:bg-green-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:person-check-outline-rounded' },
    4: { class: 'bg-red-500 dark:bg-red-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:close-rounded' },
    5: { class: 'bg-purple-500 dark:bg-purple-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:local-activity-outline-rounded' },
    6: { class: 'bg-green-500 dark:bg-green-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:check-rounded' },
    7: { class: 'bg-fuchsia-500 dark:bg-fuchsia-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:edit-outline-rounded' },
    8: { class: 'bg-pink-500 dark:bg-pink-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:move-up-rounded' },
    9: { class: 'bg-orange-500 dark:bg-orange-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:warning-rounded' },
    10: { class: 'bg-red-500 dark:bg-red-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:calendar-clock-rounded' },
    11: { class: 'bg-red-500 dark:bg-red-600 bg-opacity-90 dark:bg-opacity-50', icon: 'material-symbols:close-rounded' },
};
</script>
<template>
    <div class="rounded-xl">
        <NuxtLink class="w-full flex gap-3" :to="notification.link || ''" external>
            <Avatar :class="iconStyle[notification.categoryId].class">
                <AvatarImage :src="notification.createdBy?.imageUrl || ''" />
                <AvatarFallback><IconCSS class="text-white" size="1.5rem" :name="iconStyle[notification.categoryId].icon" /></AvatarFallback>
            </Avatar>
            <div>
                <MDC class="text-stone-800 dark:text-stone-300" :value="notification.title" tag />
                <p class="text-sm text-stone-600 dark:text-stone-500">{{ notification.description }}</p>
                <p class="text-xs text-stone-600 dark:text-stone-500">โดย: {{ notification.createdBy?.fullName || notification.createdByName }} เมื่อ: {{ timeAgo }}</p>
            </div>
        </NuxtLink>
    </div>
</template>
